import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import React, { Fragment, useEffect, useState } from 'react';

import { recoverPassword } from '../../api/auth';

import s from './index.module.scss';

import Seo from '../../components/Seo';
import Input from '../../components/input';
import Button from '../../components/button';
import validations from '../../utils/validations';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const PasswordReset = () => {
  const { handleSubmit, register, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);

  const [err, setError] = useState({
    status: false,
    msg: '',
  });

  useEffect(() => {}, []);

  const onSubmit = ({ email }) => {
    setLoading(true);
    recoverPassword(email)
      .then(() => {
        setLoading(false);
        setDialog(true);
      })
      .catch((err) => {
        setLoading(false);
        switch (err.code) {
          case 'auth/user-not-found':
            return setError({
              status: true,
              msg: 'Este correo no está registrado',
            });

          default:
            navigate('/404');
        }
      });
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Recupera tu contraseña de inicio de sesión en coronasunsetchile.com"
        title="Recuperar contraseña"
      />

      <Header title="Recuperar Contraseña" />

      <div className={s.container}>
        {dialog ? (
          <div className={s.inputsContainer}>
            <h1>Correo enviado correctamente</h1>
            <p className={s.text}>
              Revisa tu casilla de correo electrónico para completar la
              recuperación de tu cuenta
            </p>

            <Button
              className="auth"
              value="Iniciar sesión"
              onClick={() => navigate('/login', { replace: true })}
              styles={{
                background: '#ffc72c',
                minWidth: '148px',
                maxWidth: '148px',
              }}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={s.inputsContainer}>
            <h1>Recuperar contraseña</h1>

            <Input
              width="30%"
              type="text"
              name="email"
              className="field"
              placeholder="Correo electrónico"
              err={errors.old_password}
              referencia={register(validations.password)}
            />

            {err.status && <div className={s.err}>{err.msg}</div>}

            <div className={s.btnContainer}>
              <Button
                type="submit"
                className="auth"
                value="Recuperar Contraseña"
                loading={loading}
                styles={{
                  background: '#ffc72c',
                  minWidth: '148px',
                }}
              />
            </div>
          </form>
        )}
      </div>

      <Footer />
    </Fragment>
  );
};

export default PasswordReset;
